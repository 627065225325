.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  overflow: visible;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.rbc-calendar *, .rbc-calendar :before, .rbc-calendar :after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999;
}

.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  flex: 1 0;
  min-height: 0;
  padding: 0 3px;
  font-size: 90%;
  font-weight: bold;
  overflow: hidden;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  cursor: pointer;
  -webkit-user-select: text;
  user-select: text;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.rbc-row-content {
  -webkit-user-select: none;
  user-select: none;
  z-index: 4;
  position: relative;
}

.rbc-row-content-scrollable {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  overflow-y: scroll;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}

.rbc-toolbar button {
  color: #373a3c;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0;
  padding: .375rem 1rem;
  line-height: normal;
  display: inline-block;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
  box-shadow: inset 0 3px 5px #00000020;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.rbc-toolbar button:focus, .rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  white-space: nowrap;
  display: inline-block;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 0 4px 4px 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px 0 0 4px;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button + button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

@media (width <= 767px) {
  .rbc-toolbar {
    flex-direction: column;
  }
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  text-align: left;
  background-color: #3174ad;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  padding: 2px 5px;
}

.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px #33333380;
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-row {
  flex-direction: row;
  display: flex;
}

.rbc-row-segment {
  padding: 0 1px 1px;
}

.rbc-selected-cell {
  background-color: #0000001a;
}

.rbc-show-more {
  z-index: 4;
  color: #3174ad;
  background-color: #ffffff4d;
  height: auto;
  font-size: 85%;
  font-weight: bold;
  line-height: normal;
}

.rbc-show-more:hover, .rbc-show-more:focus {
  color: #265985;
}

.rbc-month-view {
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #ddd;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.rbc-month-header {
  flex-direction: row;
  display: flex;
}

.rbc-month-row {
  flex-direction: column;
  flex: 1 0 0;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-date-cell {
  text-align: right;
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  flex-direction: row;
  flex: 1 0 0;
  display: flex;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  z-index: 5;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 10px;
  position: absolute;
  box-shadow: 0 5px 15px #00000040;
}

.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  flex-direction: column;
  flex: 1 0 0;
  display: flex;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  vertical-align: top;
  padding: 5px 10px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  text-transform: lowercase;
  padding-left: 15px;
  padding-right: 15px;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 3px 5px;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell, .rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  flex-flow: column;
  min-height: 40px;
  display: flex;
}

.rbc-time-gutter, .rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 10px;
  position: absolute;
  inset: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  flex-flow: column wrap;
  align-items: flex-start;
  min-height: 20px;
  max-height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.rbc-day-slot .rbc-background-event {
  opacity: .75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  width: auto;
  padding-right: 5px;
}

.rbc-day-slot .rbc-event-content {
  word-wrap: break-word;
  flex: 1 1 0;
  width: 100%;
  height: 100%;
  min-height: 1em;
  line-height: 1;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
  z-index: 10;
  background-color: #fff;
  border-right: 1px solid #ddd;
  margin-right: -1px;
  position: sticky;
  left: 0;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  flex: 1 0 0;
  min-width: auto;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header, .rbc-time-view-resources .rbc-day-bg {
  flex: 1 1 0;
  -ms-flex-preferred-size: 0 px;
  flex-basis: 0 px;
  width: 140px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  color: #fff;
  background-color: #00000080;
  width: 100%;
  padding: 3px;
  font-size: 75%;
  position: absolute;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  border: 1px solid #ddd;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  display: flex;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
  z-index: 4;
  position: relative;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  flex-direction: row;
  flex: none;
  display: flex;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-left: 1px solid #ddd;
  border-right-width: 0;
}

.rbc-time-header > .rbc-row:first-child, .rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  border-left: 1px solid #ddd;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  display: flex;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  border-top: 2px solid #ddd;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-content > .rbc-day-slot {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.rbc-current-time-indicator {
  z-index: 3;
  pointer-events: none;
  background-color: #74ad31;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
}
/*# sourceMappingURL=index.6ec6e2af.css.map */
